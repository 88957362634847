<template>
  <div>
    <table-component
      ref="tableComponent"
      :model="model"
      selectable
      :show-form="false"
      :permissions="permissions"
    >

      <template #actions>
        <b-button
          :variant="onlyPendingVerification ? 'primary' : 'outline-primary'"
          class="btn-icon"
          @click="showOnlyPendingVerification($event)"
        >
          Mostrar pendientes de verificación
        </b-button>
      </template>

    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import ModelAgent from '@/dc-it/models/ModelAgent'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ModelAgentsView',
  components: { TableComponent, BButton },
  data() {
    return {
      model: ModelAgent,
      onlyPendingVerification: false,
      permissions: {
        create: false,
        update: false,
        delete: true,
        filter: true,
      },
    }
  },
  methods: {
    showOnlyPendingVerification() {
      if (this.onlyPendingVerification) {
        this.onlyPendingVerification = false
        this.$refs.tableComponent.filterFields = []
        this.$refs.tableComponent.clearFilter()
      } else {
        this.$refs.tableComponent.filterFields = [
          {
            field: 'status',
            value: 0,
          },
        ]
        this.$refs.tableComponent.applyFilter()
        this.onlyPendingVerification = true
      }
    },
  },
}
</script>

<style scoped>

</style>
